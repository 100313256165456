import React from 'react'
import './portfoliobanner.css'
import Img from 'gatsby-image'
import Boop from '../Boop'

import beeoLogo from '../../images/beeo.svg'
import gymmyLogo from '../../images/gymmy-link-icon.svg'
import treepLogo from '../../images/treep.svg'

const PortfolioBanner = ({ id, project_name, background, picture, color, title, description, url }) => {
	return (
		<section className="portfoliopage__banner" style={{ background: background }}>
			<div className="banner__text-wrap">
				<h1 className="banner__title" style={{ color: !!color ? color : '#ffffff' }}>
					{title}
				</h1>
				<p className="banner__description" style={{ color: !!color ? color : '#ffffff' }}>
					{description}
				</p>
			</div>
			<div
				className={`banner__picture ${
					(project_name === 'valentinamiller' && 'valentinamiller') || (project_name === 'dzing' && 'dzing') || (project_name === 'transportspb' && 'transportspb') || ''
				}`}
			>
				<Img fluid={picture} />
			</div>
			{!!url && (
				<Boop path={url.path} styles={'banner__url'} scale={1.04}>
					<img
						className="url__picture"
						src={
							(project_name === 'treeps' && treepLogo) || (project_name === 'beeo' && beeoLogo) || (project_name === 'gymmy' && gymmyLogo)
						}
						alt=""
					/>
					<span className="url__content">{url.content}</span>
				</Boop>
			)}
		</section>
	)
}

export default PortfolioBanner
