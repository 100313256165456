import React from 'react'
import SwiperCore, { Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Img from 'gatsby-image'
import './portfolioslider.css'

SwiperCore.use([Lazy])


const PortfolioSlider = ({ slides, backgroundSlider, isSkaut }) => {
  const renderSlides = slides.map(({ node }, index) => (
    <SwiperSlide key={index} className="portfoliopage__slide">
      <Img
        fluid={node.childImageSharp.fluid}
        style={{
          display: 'block',
          width: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </SwiperSlide>
  ))

  return (
    <section className="portfoliopage__slider" style={{ background: backgroundSlider }}>
      <Swiper
        breakpoints={{
          0: {
            spaceBetween: 23,
          },
          768: {
            spaceBetween: 40,
          },
          992: {
            spaceBetween: 40,
          },
        }}
        slidesPerView={'auto'}
        loop={true}
        simulateTouch={true}
        grabCursor={true}
        updateOnWindowResize={true}
        autoHeight={true}
        centeredSlides={true}
        lazy={true}
      >
        {renderSlides}
      </Swiper>
    </section>
  )
}

export default PortfolioSlider
