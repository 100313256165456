import React from 'react'
import { graphql } from 'gatsby'
import PortfolioPage from './PortfolioPage'

export const query = graphql`
  query WorkQuery($project_name: String) {
    portfolioJson(project_name: { eq: $project_name }) {
      id
      project_name
      title
      description
      googleplayurl
      appstoreurl
      banner
      backgroundColor
      backgroundSlider
      color
      downloads
      aboutTheProject
      isSkaut

      inAppRow {
        image
        title
        text
      }
      inAppCol {
        image
        title
        text
      }
      url {
        path
        content
      }
      reviews {
        id
        stars
        title
        description
        date
        nickname
        platform
      }
     
      prevPage {
        path
        icon
        name
      }
      nextPage {
        path
        icon
        name
      }
    }
  }
`

const PortfolioPageContainer = ({ data }) => {
  return <PortfolioPage data={data} />
}

export default PortfolioPageContainer

//  feedback {
//         avatar
//         customer
//         position
//       }
