import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import './portfolioreviews.css'

import StarImg from '../../images/star-full.svg'

const PortfolioReviews = ({ reviews, feedback, downloads }) => {
  const { avatars } = useStaticQuery(graphql`
    query {
      avatars: allFile(filter: { relativePath: { regex: "/review-/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 105, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  let avatar
  if (!!feedback) {
    avatar = avatars.edges.find(({ node }) => node.relativePath === feedback.avatar)
  }

  return (
    <section className="portfoliopage__reviews">
      <div className="container">
        <div className="reviews__header">
          <h3 className="reviews__title">Отзывы</h3>
          <span className={`reviews__downloads ${!!feedback ? 'reviews__downloads_s' : ''}`}>
            Скачало
            <br className="hidden s:block xs:hidden" /> {downloads} чел.
          </span>
        </div>
        {!!feedback && (
          <div className="reviews__feedback-wrap">
            <div className="reviews__feedback">
              <div className="feedback__header">
                <div className="feedback__avatar">
                  {!!avatar && <Img fluid={avatar.node.childImageSharp.fluid} />}
                </div>
                <div className="feedback__header-text-block">
                  <h5 className="feedback__header-title">{feedback.customer}</h5>
                  <h4 className="feedback__header-subtitle">{feedback.position}</h4>
                </div>
              </div>
              <p className="feedback__description">{feedback.feedback}</p>
            </div>
            <span className="reviews__downloads reviews__downloads_md">
              Скачало
              <br className="hidden s:block xs:hidden" /> {downloads} чел.
            </span>
          </div>
        )}
        <div className="reviews__block">
          <ul className="reviews__list">
            {reviews.map((review) => (
              <li key={review.id} className="reviews__item">
                <div>
                  <div className="item__stars">
                    {Array(review.stars)
                      .fill(0)
                      .map((_, index) => (
                        <img key={index} src={StarImg} alt="" />
                      ))}
                  </div>
                  <div className="item__header">
                    {!!review.title && <span className="item__title">{review.title}</span>}
                    <span className="item__date">{review.date}</span>
                  </div>
                  <p className="item__description">{review.description}</p>
                </div>
                <div className="item__footer">
                  <span className="item__nickname">{review.nickname}</span>
                  <span className="item__platform">{review.platform}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default PortfolioReviews
