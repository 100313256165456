import React from 'react'
import Img from 'gatsby-image'

import './portfolioinapprow.css'

const PorfolioAppRow = ({ inAppRow, inAppImages }) => {
	let _image = inAppImages.sort((a, b) => a.node.relativePath > b.node.relativePath ? 1 : -1 )
	return (
		<section className="portfoliopage__inapprow container">
      		<div className="container">
			  	<h2 className="inapp__title">В Приложении</h2>
			  	{inAppRow.map((element, index) => (
					<div className='inapp__block'>
						<div className='inapp__image'>
							{console.log(inAppImages)}
							<Img fluid={_image[index].node.childImageSharp.fluid} />
						</div>
						<div className='inapp__descr'>
							<h3 className='inapp__descr-title'>
								{element.title}
							</h3>
							<p className='inapp__descr-text'>
								{element.text}
							</p>
						</div>
					</div>
				))}
			</div>
		</section>
	)
}

export default PorfolioAppRow
