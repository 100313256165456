import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { animated } from 'react-spring'
import { Link } from 'gatsby'
import './portfoliopagination.css'
import { useBooped } from '../../hooks/useBooped'

const PortfolioPagination = ({ prevPage, nextPage }) => {
	const { icons } = useStaticQuery(graphql`
		query {
			icons: allFile(filter: { relativePath: { regex: "/-iconlogo/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 70, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
						publicURL
					}
				}
			}
		}
	`)

	let iconPrev
	let iconNext
	if (!!prevPage && !!nextPage) {
		iconPrev = icons.edges.find(({ node }) => node.relativePath === prevPage.icon)
		iconNext = icons.edges.find(({ node }) => node.relativePath === nextPage.icon)
	}

	const [isBoopedPrev, setIsBoopedPrev] = useState(false)
	const [isBoopedNext, setIsBoopedNext] = useState(false)
	const stylePrev = useBooped({ isBooped: isBoopedPrev, setIsBooped: setIsBoopedPrev, y: -1 })
	const styleNext = useBooped({ isBooped: isBoopedNext, setIsBooped: setIsBoopedNext, y: -1 })

	const triggerPrev = () => setIsBoopedPrev(true)
	const triggerNext = () => setIsBoopedNext(true)

	return (
		<section className="portfoliopage__pagination container">
			<div className="pagination__wrap">
				<Link to={`/portfolio/${prevPage.path}/`} className="pagination__link" onMouseEnter={triggerPrev}>
					<div className="pagination__text-block">
						<span className="pagination__title">Предыдущий проект</span>
						<span className="pagination__name">{prevPage.name}</span>
					</div>
					{!!iconPrev && (
						<animated.img
							style={stylePrev}
							src={iconPrev.node.publicURL}
							alt=""
							className="pagination__icon pagination__icon_left"
						/>
					)}
				</Link>
				<Link to={`/portfolio/${nextPage.path}/`} className="pagination__link" onMouseEnter={triggerNext}>
					{!!iconNext && (
						<animated.img
							style={styleNext}
							src={iconNext.node.publicURL}
							alt=""
							className="pagination__icon pagination__icon_right"
						/>
					)}
					<div className="pagination__text-block">
						<span className="pagination__title">Следующий проект</span>
						<span className="pagination__name">{nextPage.name}</span>
					</div>
				</Link>
			</div>
		</section>
	)
}

export default PortfolioPagination
