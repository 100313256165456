import React from 'react'
import Layout from '../Layout'
import { graphql, useStaticQuery } from 'gatsby'
import './portfoliopage.css'

import PortfolioBanner from '../PortfolioBanner'
import PortfolioDescription from '../PortfolioDescription'
import PortfolioSlider from '../PortfolioSlider/PortfolioSlider'
import PortfolioReviews from '../PortfolioReviews'
import PorfolioAppRow from '../PorfolioAppRow'
import PorfolioAppCol from '../PorfolioAppCol'
import HomeRequest from '../HomeRequest'
import PortfolioPagination from '../PortfolioPagination'

import SkautContentBlock from '../SkautContentBlock'

const PortfolioPage = ({ data }) => {
  const { banners, slides, inapimages} = useStaticQuery(graphql`
    query {
      banners: allFile(filter: { relativePath: { regex: "/-banner/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      slides: allFile(filter: { relativePath: { regex: "/-slide/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 289, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      inapimages: allFile(filter: { relativePath: { regex: "/-inapp-/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const {
    id,
    project_name,
    url,
    description,
    title,
    banner,
    color,
    backgroundColor,
    backgroundSlider,
    aboutTheProject,
    googleplayurl,
    appstoreurl,
    reviews,
    feedback,
    downloads,
    prevPage,
    nextPage,
    inAppRow,
    inAppCol,
    isSkaut
  } = data.portfolioJson


  const bannerPicture = banners.edges.find(({ node }) => node.relativePath === banner)
  const filterSlides = slides.edges.filter(({ node }) => node.relativePath.includes(project_name))
  const inAppImages = inapimages.edges.filter(({ node }) => node.relativePath.includes(project_name))

  return (
    <Layout
      title={`AppCraft | ${title}`}
      content={description}
      keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
      ogSiteName="AppCraft"
      ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
      ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
    >
      <section className="portfoliopage">
        <PortfolioBanner
          id={id}
          project_name={project_name}
          url={url}
          title={title}
          description={description}
          picture={bannerPicture.node.childImageSharp.fluid}
          color={color}
          background={backgroundColor}
        />
        <PortfolioDescription
          description={aboutTheProject}
          googleplayurl={googleplayurl}
          appstoreurl={appstoreurl}
          isSkaut={isSkaut}
        />
        <PortfolioSlider backgroundSlider={backgroundSlider} slides={filterSlides} isSkaut={isSkaut} />
        {reviews.length ? <PortfolioReviews reviews={reviews} feedback={feedback} downloads={downloads} /> : ''}

        {inAppRow !== null ? <PorfolioAppRow inAppRow={inAppRow} inAppImages={inAppImages}/> : ''}
        {inAppCol !== null ? <PorfolioAppCol inAppCol={inAppCol} inAppImages={inAppImages}/> : ''}
        {isSkaut ? <SkautContentBlock /> : ''}

        <HomeRequest />
        <PortfolioPagination prevPage={prevPage} nextPage={nextPage} />
      </section>
    </Layout>
  )
}

export default PortfolioPage
