import React, { useEffect } from 'react'
import { useSpring } from 'react-spring'

export const useBooped = ({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  isBooped = false,
  setIsBooped,
  tension = 300,
  friction = 10,
  timing = 150,
}) => {
  const style = useSpring({
    backfaceVisibility: 'hidden',
    transform: isBooped
      ? `perspective(1px) translate(${x}px, ${y}px)
                 rotate(${rotation}deg)
                 scale(${scale})
                 `
      : `perspective(1px) translate(0px, 0px)
                 rotate(0deg)
                 scale(1)`,
    config: {
      tension,
      friction,
    },
  })

  useEffect(() => {
    setTimeout(() => {
      setIsBooped(false)
    }, timing)
  }, [isBooped])

  return style
}
