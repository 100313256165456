import React from 'react'
import './portfoliodescription.css'
import Boop from '../Boop'

import appstoreImg from '../../images/appstore-rus.svg'
import googleplayImg from '../../images/googleplay-rus.svg'
import skautlogo from '../../images/skaut-logo.png'

const PortfolioDescription = ({ description, appstoreurl, googleplayurl, isSkaut }) => {
  return (
    <section className="portfoliopage__description">
      <div className="container">
        <div className="description__links">
          {!!appstoreurl && (
            <Boop path={appstoreurl} styles={'description__link description__link_apple'} scale={1.03}>
              <img src={appstoreImg} alt="" />
            </Boop>
          )}
          {!!googleplayurl && (
            <Boop path={googleplayurl} styles={'description__link description__link_apple'} scale={1.03}>
              <img src={googleplayImg} alt="" />
            </Boop>
          )}
        </div>
        {isSkaut ? (
          <>
          <div className="description__abouttheproject">
            <h2 className="abouttheproject__title abouttheproject__title--skaut">Задача клиента </h2>
            <p className="abouttheproject__description">
              <img src={skautlogo} alt="skautlogo" />
            Компания СКАУТ — поставщик решений для спутникового мониторинга транспорта. Заказчику требовалось создать более удобную для пользователей версию своего программного обеспечения. Десктопная и веб-версия у авторов продукта уже существовала, однако для развития и получения преимущества в отрасли важна портативность использования сервиса — для доступа к нему в любое время и любом месте. 
            </p>
          </div>
          <div className="description__abouttheproject">
            <h2 className="abouttheproject__title abouttheproject__title--skaut">Наше решение </h2>
            <p className="abouttheproject__description">
            В приложении, разработанном для платформ iOS и Android, информацию о любом транспортном объекте можно получать либо находя его через поиск, либо выбирая на карте — вариантов карт внедрили несколько, ведь каждому привычен свой гео-сервис. В карточке автомобиля в пределах одного экрана отображается как его текущая геолокация, так и переходы между общей информацией, построением отчетов и треков за любой необходимый период. Дизайнер выстроил оптимальный интерфейс в соответствии с принципами UX/UI. Данные автомобилей приложение получает, взаимодействуя с сервером по API заказчика. 
            </p>
          </div>
          </>
        ) : (

          <div className="description__abouttheproject">
            <h2 className="abouttheproject__title">О проекте</h2>
            <p className="abouttheproject__description">{description}</p>
          </div>
        )}
       
      </div>
    </section>
  )
}

export default PortfolioDescription
