import React from 'react'
import Img from 'gatsby-image'

import './portfolioinappcol.css'

const PorfolioAppCol = ({ inAppCol, inAppImages }) => {
	let _image = inAppImages.sort((a, b) => a.node.relativePath > b.node.relativePath ? 1 : -1 )
	return (
		<section className="portfoliopage__inappcol container">
      		<div className="container">
				<div className='portfoliopage__inappcol-grid'>
					{inAppCol.map((element, index) => (
						<div className='inapp__block'>
							<div className='inapp__image'>
								{console.log(inAppImages)}
								<Img fluid={_image[index].node.childImageSharp.fluid} />
							</div>
							<div className='inapp__descr'>
								<h3 className='inapp__descr-title'>
									{element.title}
								</h3>
								<p className='inapp__descr-text'>
									{element.text}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default PorfolioAppCol
